import React from 'react';
import classes from "./Header.module.css";
import logo from '../../../images/png/CMicon.gif'
import logoSM from '../../../images/png/logoSM.png'
import ButtonTg from "../buttonTG/ButtonTG";
import ButtonLogin from "../buttonLogin/ButtonLogin";
import MediaQuery from "react-responsive";

const Header = ({links}) => {
    const [scroll, setScroll] = React.useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div className={classes.logoBlock}>
                    <img src={logo} className={classes.logo} alt={'logo'} style={{height: scroll > 50 ? '40px' : '55px'}}/>
                    <div className={classes.logoName} style={{opacity: scroll > 50 ? 0 : 1}}>
                        <img src={logoSM} alt={''} className={classes.logoNameSVG}/>
                    </div>
                </div>
                <div className={classes.buttons}>
                    <ButtonTg icon={'youtube'} small={scroll > 50} link={()=>window.open(links.youtube)}/>
                    <ButtonTg icon={'tg'} small={scroll > 50} link={()=>window.open(links.tg)}/>
                    <MediaQuery maxWidth={1023}>
                        <ButtonTg icon={'account'} small={scroll > 50}/>
                    </MediaQuery>
                    <MediaQuery minWidth={1024}>
                        {scroll > 50 ? <ButtonTg icon={'account'} small={scroll > 50}/>:
                            <ButtonLogin/>
                        }
                    </MediaQuery>
                </div>
            </div>
            <svg className={classes.fon} width="1080" height="115" viewBox="0 0 1080 115" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M86 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M86 -68V467; M6 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M166 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M166 -68V467; M86 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M246 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M246 -68V467; M166 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M326 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M326 -68V467; M246 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M406 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M406 -68V467; M326 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M486 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M486 -68V467; M406 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M566 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M566 -68V467; M486 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M646 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M646 -68V467; M566 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M726 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M726 -68V467; M646 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M806 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M806 -68V467; M726 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M886 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M886 -68V467; M806 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M966 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M966 -68V467; M886 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M1046 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M1046 -68V467; M966 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M1126 -68V467" stroke="#352343">
                    <animate
                        attributeName="d"
                        values="M1126 -68V467; M1046 -68V467"
                        dur="8s"
                        repeatCount="indefinite"/>
                </path>
                <path d="M0 25L1080 25" stroke="#352343"/>
                <path d="M0 59L1080 59" stroke="#352343"/>
                <path d="M0 93L1080 93" stroke="#352343"/>
                <path d="M0 126L1080 126" stroke="#352343"/>
            </svg>
        </div>
    );
};

export default Header;