import React from 'react';
import classes from "./buttonMain.module.css";

const ButtonMain = ({ text, str, color, click, fl }) => {
    const colors = [
        classes.color0,
        classes.color1
    ]
    const pd = fl === 1 ? classes.pd : '';
    return (
        <div className={classes._ + ' ' + colors[color] + ' ' + pd} onClick={click}>
            <div className={classes.text}>{text}</div>
            {str && <div className={classes.text}>{'>'}</div>}
        </div>
    );
};

export default ButtonMain;