import React from 'react';
import classes from "./popup.module.css";

const Popup = ({children, header, close}) => {
    return (
        <div className={classes._} onClick={close}>
            <div className={classes.block} onClick={e=>e.stopPropagation()}>
                <div className={classes.header}>
                    <div>{header}</div>
                    <svg className={classes.close} xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none" onClick={close}>
                        <path className={classes.closePath} fillRule="evenodd" clipRule="evenodd" d="M18.7782 6.05045C19.5592 5.2694 19.5592 4.00307 18.7782 3.22202C17.9971 2.44097 16.7308 2.44097 15.9497 3.22202L11 8.17177L6.05025 3.22202C5.2692 2.44097 4.00287 2.44097 3.22183 3.22202C2.44078 4.00307 2.44078 5.2694 3.22183 6.05045L8.17157 11.0002L3.22183 15.9499C2.44078 16.731 2.44078 17.9973 3.22182 18.7784C4.00287 19.5594 5.2692 19.5594 6.05025 18.7784L11 13.8286L15.9497 18.7784C16.7308 19.5594 17.9971 19.5594 18.7782 18.7784C19.5592 17.9973 19.5592 16.731 18.7782 15.9499L13.8284 11.0002L18.7782 6.05045Z" fill="white"/>
                    </svg>
                </div>
                <div className={classes.info}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Popup;