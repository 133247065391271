import React from "react";
import classes from "./MainContent.module.scss";
import RegularTag from "../../../common/tags/RegularTag";
import SimpleSlider from "../../../common/utilityBlocks/SimpleSlider/SimpleSlider";
import { SERVER_URL } from "../../../../utils/api";
import Crums from "../../../common/bredCrums/Crums";
import { format } from "date-fns";
import { ru } from "date-fns/locale";

function MainContent({ article }) {
    const crumsData = [ ["blog", "блог"]];
    const { description, preview_text, title, dt, photo_url, tag, data } = article;
    const photo = SERVER_URL + photo_url;
    function formatDate(dateString) {
        const date = new Date(dateString);
        return format(date, "d MMMM yyyy", { locale: ru });
    }
    console.log(article)
    return (
        <div className={classes._}>
            <div className={classes.block_}>
                <Crums data={crumsData} />
                <div className={classes.information}>
                    <span className={classes.informationDateCreated}>Дата публикации:</span>
                    <span className={classes.informationData}>{formatDate(dt)}</span>
                    <RegularTag>{tag}</RegularTag>
                </div>
                <div className={classes.content}>
                    <h1>{title}</h1>
                    {data.map((item, index) => {
                        switch (item.inp_type) {
                            case "text":
                                return (
                                    <div key={index} dangerouslySetInnerHTML={{ __html: item.value }} />
                                );
                            case "photo":
                                return (
                                    <SimpleSlider key={index}>
                                        <img className={classes.ssImage} src={SERVER_URL + item.photo_url} alt="picture" draggable="false" />
                                    </SimpleSlider>
                                );
                            case "video":
                                return (
                                    <SimpleSlider key={index}>
                                        <video className={classes.ssVideo} autoPlay={false} loop playsInline controls>
                                            <source src={SERVER_URL + item.video_url} type="video/mp4" />
                                            Ваш браузер не поддерживает видео.
                                        </video>       
                                    </SimpleSlider>
                                );
                            case "video_link":      
                                return (
                                    <SimpleSlider key={index}>
                                        <iframe
                                            className={classes.ssVideo}
                                            min-height="25vw"
                                            src={`https://www.youtube.com/embed/${item.value.split('/').pop()}`}
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope;"
                                            allowFullScreen
                                        ></iframe>      
                                    </SimpleSlider>
                                );
                            default:
                                return null;
                        }
                    })}
                    {description}
                </div>
            </div>
        </div>
    );
}

export default MainContent;