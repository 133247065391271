import React, {useEffect, useRef, useState} from 'react';
import classes from "./Menu.module.css";
import MediaQuery from "react-responsive";
import ButtonTg from "../buttonTG/ButtonTG";
import ButtonLogin from "../buttonLogin/ButtonLogin";
import ButtonTGBlack from "../buttonTGBlack/ButtonTGBlack";

const Menu = () => {
    const [active, setActive] = useState(0)
    const [open, setOpen] = useState(false)
    const refTooltip = useRef(null)
    const [MousePosition, setMousePosition] = useState({
        left: 0,
        top: 0
    })

    const menuItems = [
        {id:0, name:'главная',href:'/', work:true},
        {id:1, name:'услуги',href:'#learn', work:true},
        {id:2, name:'цены',href:'#price', work:true},
        {id:3, name:'о школе',href:'#about', work:true},
        {id:4, name:'магазин',href:'', work:false},
        {id:5, name:'блог',href:'', work:false},
        //{id:6, name:'контакты',href:'', work:true},
    ]

    function handleMouseMove(ev,) {
        refTooltip.current.style.display='block'
        setMousePosition({left: ev.screenX+15, top: ev.screenY+30});
    }

    const [scroll, setScroll] = React.useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={classes._} style={{borderBottom:open?"3px #AA55EC solid":"3px #0C090F solid"}}>
            <MediaQuery minWidth={920}>
                <div className={classes.menu}>
                    {menuItems.map(item =>
                        <a key={item.id}
                             className={classes.item + ' ' + (!item.work && classes.itemNotWork) + ' ' + (item.id === active && classes.itemActive)}
                             onMouseMove={(ev)=> {if(!item.work)handleMouseMove(ev)}}
                             onMouseOut={()=>refTooltip.current.style.display='none'}
                             href={item.work?item.href:null}
                        >
                            {item.name}
                        </a>)}
                    <div ref={refTooltip} style={MousePosition} className={classes.tooltip}>СКОРО</div>
                </div>
            </MediaQuery>
            <MediaQuery maxWidth={919}>
                <div className={classes.menu2}>
                    <div className={classes.buttons} style={{opacity:scroll > 70?1:0, zIndex:scroll > 70?1:-1}}>
                        <ButtonTGBlack icon={'youtube'}/>
                        <ButtonTGBlack icon={'tg'}/>
                        <ButtonTGBlack icon={'account'}/>
                    </div>
                    <div className={classes.item} onClick={()=>setOpen(!open)}>
                        меню
                        <svg className={classes.burger} style={{opacity:open?0:1}} xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M0 1.5C0 0.671573 0.671573 0 1.5 0H28.5C29.3284 0 30 0.671573 30 1.5C30 2.32843 29.3284 3 28.5 3H1.5C0.671573 3 0 2.32843 0 1.5ZM0 9.5C0 8.67157 0.671573 8 1.5 8H28.5C29.3284 8 30 8.67157 30 9.5C30 10.3284 29.3284 11 28.5 11H1.5C0.671573 11 0 10.3284 0 9.5ZM1.5 16C0.671573 16 0 16.6716 0 17.5C0 18.3284 0.671573 19 1.5 19H28.5C29.3284 19 30 18.3284 30 17.5C30 16.6716 29.3284 16 28.5 16H1.5Z" fill="#AA55EC"/>
                        </svg>
                        <svg className={classes.burger2} style={{opacity:open?1:0}} xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M6.54615 15.7609C5.81795 16.5019 5.81795 17.7033 6.54615 18.4442C7.27434 19.1852 8.45498 19.1852 9.18318 18.4442L15.4999 12.0168L21.8168 18.4445C22.545 19.1855 23.7257 19.1855 24.4538 18.4445C25.182 17.7035 25.182 16.5022 24.4538 15.7612L18.1369 9.33348L24.1263 3.239C24.8545 2.49803 24.8545 1.29669 24.1263 0.555725C23.3981 -0.185241 22.2175 -0.185242 21.4893 0.555724L15.4999 6.65021L9.55388 0.599954C8.82569 -0.141012 7.64505 -0.141012 6.91685 0.599954C6.18866 1.34092 6.18866 2.54226 6.91685 3.28323L12.8628 9.33348L6.54615 15.7609Z" fill="#AA55EC"/>
                        </svg>
                    </div>
                </div>
                {open &&
                    <div className={classes.menuFon} style={{top:scroll > 181-66?66:181-scroll, height: `calc(100vh - ${scroll > 181-66?66:181-scroll}px)`}}>
                        <div className={classes.menuBlock}>
                            {menuItems.map(item =>
                                <a key={item.id}
                                   className={classes.item + ' ' + (!item.work && classes.itemNotWork) + ' ' + (item.id === active && classes.itemActive)}
                                   href={item.href}
                                   onClick={()=>setOpen(false)}
                                >
                                    {item.name}
                                </a>)}
                        </div>
                        <div className={classes.menuTextButton}>
                            all rights reserved 2024 | Design by Cryptomania
                        </div>
                    </div>
                }
            </MediaQuery>
        </div>
    );
};

export default Menu;