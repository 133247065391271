import React from 'react';
import classes from "./ButtonLogin.module.css";

const ButtonLogin = () => {
    return (
        <div className={classes._}>
            <svg className={classes.icon} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M9.0004 10.4453C11.8767 10.4453 14.2083 8.10705 14.2083 5.22266C14.2083 2.33826 11.8767 0 9.0004 0C6.12414 0 3.79247 2.33826 3.79247 5.22266C3.79247 8.10705 6.12414 10.4453 9.0004 10.4453ZM17.888 15.8649C18.1943 16.3911 17.8404 17.017 17.2436 17.1375L16.9155 17.2037C11.6913 18.2583 6.30913 18.2583 1.08499 17.2037L0.756875 17.1375C0.160119 17.017 -0.193834 16.3911 0.112445 15.8649C0.766291 14.7417 1.78824 13.7547 3.09224 13.0008C4.78715 12.021 6.86385 11.4899 9.00024 11.4899C11.1366 11.4899 13.2133 12.021 14.9082 13.0008C16.2122 13.7547 17.2342 14.7417 17.888 15.8649Z" fill="#AA55EC"/>
            </svg>
            <div className={classes.text}>личный кабинет</div>
        </div>
    );
};

export default ButtonLogin;