import React, {useEffect, useState} from 'react';
import classes from "./footer.module.css";
import logo from "../../../images/png/CMicon.gif";
import ButtonTg from "../buttonTG/ButtonTG";
import logoSM from '../../../images/png/logoSM.png'

const Footer = ({links}) => {
    const [rows, setRows] = useState([])
    useEffect(()=>{
        let temp = []
        for(let i=0; i<15; i++){
            temp.push({d:`M${6+(i+1)*80} -200V467`, val:`M${6+(i+1)*80} -200V467; M${6+i*80} -200V467`})
        }
        setRows(temp)
    },[])
    return (
        <div className={classes._}>
            <div className={classes.block}>
                <div>
                    <div className={classes.logoBlock}>
                        <img src={logo} className={classes.logo} alt={'logo'}/>
                        <div className={classes.logoName}>
                            <img src={logoSM} alt={''} className={classes.logoNameSVG}/>
                        </div>
                    </div>
                    <div className={classes.text}>all rights reserved 2024 | Design by Smartmania</div>
                </div>
                <div className={classes.buttons}>
                    <ButtonTg icon={'youtube'} link={()=>window.open(links.youtube)}/>
                    <ButtonTg icon={'tg'} link={()=>window.open(links.tg)}/>
                </div>
            </div>
            <svg className={classes.fon} width="1080" height="221" viewBox="0 0 1080 221" fill="none" xmlns="http://www.w3.org/2000/svg">
                {rows.map(item=>
                    <path key={item.d} d={item.d} stroke="#352343">
                        <animate
                            attributeName="d"
                            values={item.val}
                            dur="8s"
                            repeatCount="indefinite"/>
                    </path>
                )}
                <path d="M0 25L1080 25" stroke="#352343"/>
                <path d="M0 59L1080 59" stroke="#352343"/>
                <path d="M0 93L1080 93" stroke="#352343"/>
                <path d="M0 126L1080 126" stroke="#352343"/>
                <path d="M0 159L1080 159" stroke="#352343"/>
                <path d="M0 192L1080 192" stroke="#352343"/>
            </svg>
        </div>
    );
};

export default Footer;