import React, {useEffect, useRef, useState} from 'react';
import classes from "./block1.module.css";
import block1 from "../../../../images/png/block1_1.png"
import ButtonMain from "../../../common/buttonMain/buttonMain";
import discord from "../../../../images/png/discord.png"
import ButtonTg from "../../../common/buttonTG/ButtonTG";
import bullet1 from '../../../../images/png/bullet1.png'
import bullet2 from '../../../../images/png/bullet2.png'
import bullet3 from '../../../../images/png/bullet3.png'
import Popup from "../../../common/popup/popup";
import {SERVER_URL} from "../../../../utils/api";

const Block1 = ({blocks, links}) => {
    const [bullets, setBullets] = useState([
        {id:0, image:bullet1, header:'Обучение проводится<br/>в формате живого общения', text:'с преподавателем школы,<br/>что позволяет вам получать ответы на все ваши вопросы.'},
        {id:1, image:bullet2, header:'Живая торговля', text:'с преподавателями, торгуй<br/>в онлайне с учителями<br/>и улучшай навык.'},
        {id:2, image:bullet3, header:'После прохождения обучения стань частью команды.', text:'После обучения вы получаете поддержку от нашего сообщества.'}
    ])
    const [videos, setVideos] = useState([])
    const [activeVideo, setActiveVideo] = useState(0)
    const [music, setMusic] = useState(false)
    const [openPopup, setOpenPopup] = useState(0)
    const refVideo = useRef()

    useEffect(()=>{
        if(videos.length > 0)
            refVideo.current.currentTime = 0
    },[activeVideo])

    useEffect(()=>{
        setBullets([
            {id:0, image:bullet1, header:blocks.block2_1?.title, text:blocks.block2_1?.text},
            {id:1, image:bullet2, header:blocks.block2_2?.title, text:blocks.block2_2?.text},
            {id:2, image:bullet3, header:blocks.block2_3?.title, text:blocks.block2_3?.text}
        ])
        let temp = []
        if(blocks.video?.videos_urls.length > 0){
            for(let i=0; i<blocks.video?.videos_urls.length; i++){
                temp.push({id:i,src:SERVER_URL+blocks.video.videos_urls[i]})
            }
            setVideos(temp)
        }
    },[blocks])

    return (
        <div className={classes._}>
            <div className={classes.block1}>
                <div className={classes.block1_1}>
                    <div className={classes.block1_1Header}>
                        <div className={classes.headerText}>{blocks.block1_1?.header}</div>
                    </div>
                    <div className={classes.block1_2}>
                        {blocks.block1_1?.tag && <div className={classes.block1_2Text1}>{blocks.block1_1?.tag}</div>}
                        <div className={classes.block1_2Text2}>{blocks.block1_1?.title}</div>
                        <div className={classes.block1_2Text3} dangerouslySetInnerHTML={{__html: blocks.block1_1?.text}}></div>
                        <div className={classes.block1_emptyPrice}></div>
                        <img src={block1} alt={''} className={classes.block1Image}/>
                        <div className={classes.block1_2Buttons} id='learn'>
                            <ButtonMain text={'подробнее'} color={1} click={()=>setOpenPopup(1)}/>
                            <ButtonMain text={'записаться'} str={true} color={0} click={()=>window.open(links.link)}/>
                        </div>
                    </div>
                </div>
                <div className={classes.block1_3}>
                    <div style={{height:'358px'}}></div>
                    <div className={classes.block1_3_1}>
                        {videos.map((item, number)=>
                            <>
                                {activeVideo === number && <video key={item.id} autoPlay muted={!music} loop playsInline className={classes.video} ref={refVideo}>
                                    <source
                                        src={item.src}
                                        type="video/mp4"
                                    />
                                </video>}
                            </>
                        )}
                        <div className={classes.block1_3Buttons}>
                            <ButtonTg icon={'reload'} link={()=>refVideo.current.currentTime = 0}/>
                            <ButtonTg icon={music?'musicOn':'musicOff'} link={()=>setMusic(!music)}/>
                        </div>
                        <div className={classes.block1_3Buttons2}>
                            <svg className={activeVideo===0?classes.strOff:classes.str} onClick={()=>{if(activeVideo > 0)setActiveVideo(activeVideo-1)}} xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
                                <path d="M0.939339 10.9393C0.353552 11.5251 0.353552 12.4749 0.939339 13.0607L10.4853 22.6066C11.0711 23.1924 12.0208 23.1924 12.6066 22.6066C13.1924 22.0208 13.1924 21.0711 12.6066 20.4853L4.12132 12L12.6066 3.51472C13.1924 2.92893 13.1924 1.97918 12.6066 1.3934C12.0208 0.80761 11.0711 0.80761 10.4853 1.3934L0.939339 10.9393ZM29 10.5L2 10.5L2 13.5L29 13.5L29 10.5Z" fill="#0C090F"/>
                            </svg>
                            <svg className={activeVideo===videos.length-1?classes.strOff:classes.str} onClick={()=>{if(activeVideo < videos.length-1)setActiveVideo(activeVideo+1)}} xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
                                <path d="M28.0607 13.0607C28.6464 12.4749 28.6464 11.5251 28.0607 10.9393L18.5147 1.3934C17.9289 0.807611 16.9792 0.807611 16.3934 1.3934C15.8076 1.97919 15.8076 2.92893 16.3934 3.51472L24.8787 12L16.3934 20.4853C15.8076 21.0711 15.8076 22.0208 16.3934 22.6066C16.9792 23.1924 17.9289 23.1924 18.5147 22.6066L28.0607 13.0607ZM0 13.5H27V10.5H0V13.5Z" fill="#0C090F"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.block2}>
                <div className={classes.block1_1+' '+classes.block2_1}>
                    <div className={classes.block1_1Header}>
                        <div className={classes.headerText}>{blocks.block1_2?.header}</div>
                    </div>
                    <div className={classes.block1_2+' '+classes.block2_2}>
                        <svg className={classes.block2Image} xmlns="http://www.w3.org/2000/svg" width="190" height="161" viewBox="0 0 190 161" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M13.0615 69.3093L115.091 24.3724C163.677 3.70893 173.773 0.119693 180.353 0.00126842C181.801 -0.0248494 185.037 0.341869 187.133 2.08103C188.903 3.54935 189.39 5.53359 189.623 6.92535C189.856 8.31711 190.146 11.4895 189.916 13.9678C187.283 42.2538 175.89 110.899 170.094 142.58C167.641 155.986 162.816 160.481 158.138 160.92C147.978 161.876 140.263 154.054 130.422 147.459L91.3783 120.643C74.1049 109.004 85.3026 102.607 95.1468 92.1528C97.7229 89.4158 142.487 47.7833 143.353 44.0066C143.458 43.5343 143.562 41.774 142.54 40.8438C141.517 39.9136 140.006 40.2356 138.917 40.4849C137.373 40.8434 112.774 57.468 65.121 90.3592C58.1386 95.2618 51.814 97.6507 46.148 97.5255C39.9011 97.3874 27.885 93.9141 18.9522 90.9449C7.99559 87.3034 -0.712531 85.3779 0.0460668 79.1933C0.440761 75.9722 4.77925 72.6782 13.0612 69.3101L13.0615 69.3093Z" fill="white"/>
                        </svg>
                        <div className={classes.block2Info}>
                            {blocks.block1_2?.tag && <div className={classes.block1_2Text1+' '+classes.block2Text1}>{blocks.block1_2?.tag}</div>}
                            <div className={classes.block1_2Text2+' '+classes.block1_2Text2_2}>{blocks.block1_2?.title}</div>
                            <div className={classes.price+' '+classes.block2Text1}>{blocks.block1_2?.price}</div>
                        </div>
                        <ButtonMain text={'подробнее'} color={1} click={()=>setOpenPopup(2)}/>
                    </div>
                </div>
                <div className={classes.block1_1+' '+classes.block2_3}>
                    <div className={classes.block1_1Header}>
                        <div className={classes.headerText}>{blocks.block1_3?.header}</div>
                    </div>
                    <div className={classes.block1_2}>
                        {blocks.block1_3?.tag && <div className={classes.block1_2Text1}>{blocks.block1_3?.tag}</div>}
                        <div className={classes.block1_2Text2}>{blocks.block1_3?.title}</div>
                        <div className={classes.block1_2Text3 + ' '+ classes.block2_2Text3} dangerouslySetInnerHTML={{__html: blocks.block1_3?.text}}></div>
                        <div className={classes.block1_emptyPrice}></div>
                        <div className={classes.block1_2Buttons}>
                            <ButtonMain text={'подробнее'} color={1} click={()=>setOpenPopup(3)}/>
                            <ButtonMain text={'записаться'} str={true} color={0} click={()=>window.open(links.link)}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.block3} id="about">
                {bullets.map(item=>
                    <div className={classes.block3Item} key={item.id}>
                        <img src={item.image} alt={''} className={classes.block3Image}/>
                        <div className={classes.block3Text}>
                            <div className={classes.block3TextHeader} dangerouslySetInnerHTML={{__html: item.header}}></div>
                            <div dangerouslySetInnerHTML={{__html: item.text}}></div>
                        </div>
                    </div>)}
            </div>
            {openPopup === 1 &&
                <Popup header={' '} close={()=>setOpenPopup(0)}>
                    <div className={classes.block1_2Text1}>NEW</div>
                    <div className={classes.block1_2Text2}>{blocks.block1_1?.title}</div>
                    <div className={classes.block1_2Text3} dangerouslySetInnerHTML={{__html: blocks.block1_1?.all_text}}></div>
                    <div className={classes.block1_emptyPrice}></div>
                    <img src={block1} alt={''} className={classes.block1Image + ' ' + classes.block1ImageP}/>
                    <div className={classes.block1_2Buttons}>
                        <ButtonMain text={'записаться'} str={true} color={0} click={()=>window.open(links.link)}/>
                    </div>
                </Popup>
            }
            {openPopup === 2 &&
                <Popup header={'группа Discord'} close={()=>setOpenPopup(0)}>
                    <div className={classes.block2Info}>
                        <div className={classes.block1_2Text1+' '+classes.block2Text1}>vipka</div>
                        <div className={classes.block1_2Text2+' '+classes.block1_2Text2_2}>{blocks.block1_2?.title}</div>
                        <div className={classes.block1_2Text3} dangerouslySetInnerHTML={{__html: blocks.block1_2?.all_text}}></div>
                    </div>
                    <div className={classes.block1_2Buttons}>
                        <ButtonMain text={'оплатить'} str={true} color={0} click={()=>window.open(links.link)}/>
                    </div>
                </Popup>
            }
            {openPopup === 3 &&
                <Popup header={'5 пакетов обучения'} close={()=>setOpenPopup(0)}>
                    <div className={classes.block1_2Text1}>курс</div>
                    <div className={classes.block1_2Text2}>{blocks.block1_3?.title}</div>
                    <div className={classes.block1_2Text3 + ' '+ classes.block2_2Text3} dangerouslySetInnerHTML={{__html: blocks.block1_3?.all_text}}></div>
                    <div className={classes.block1_emptyPrice}></div>
                    <div className={classes.block1_2Buttons}>
                        <ButtonMain text={'записаться'} str={true} color={0} click={()=>window.open(links.link)}/>
                    </div>
                </Popup>
            }
        </div>
    );
};

export default Block1;