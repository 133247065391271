import React from 'react';
import classes from "./ButtonTariff.module.css";

const ButtonTariff = () => {
    return (
        <a className={classes._} href={'#price'}>
            <div className={classes.text}>смотреть тарифы</div>
        </a>
    );
};

export default ButtonTariff;