export const SERVER_URL = 'https://mania-smart.ru/'

export const SERVER_URL_API = 'https://mania-smart.ru/api/api/'

export async function landingBlocks(){
    let response = await fetch(SERVER_URL_API+'texts/blocks');
    return await response.json()
}

export async function getLinks(){
    let response = await fetch(SERVER_URL_API+'texts/links');
    return await response.json()
}

export async function usefulVideo(){
    let response = await fetch(SERVER_URL_API+'texts/useful-video');
    return await response.json()
}
export async function learningVideo(){
    let response = await fetch(SERVER_URL_API+'texts/training-material');
    return await response.json();
}
export async function forexArticles(){
    let response = await fetch(SERVER_URL_API+'texts/forex-analytic');
    return await response.json();
}