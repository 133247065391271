import React from 'react';
import classes from "./ButtonTG.module.css";

const ButtonTg = ({ icon, link, small }) => {
    return (
        <div className={icon === 'buyOption' ? (classes._alter) : classes._ + ' ' + (small && classes.small)} onClick={link}>
            {icon === 'tg' &&
                <svg className={classes.icon} style={{ marginRight: '4px' }} width={small ? 18 : 26} height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={classes.icon2} fillRule="evenodd" clipRule="evenodd" d="M1.78737 9.47084L15.7493 3.33039C22.398 0.50681 23.7795 0.0163556 24.6799 0.000173324C24.878 -0.00339557 25.3208 0.046715 25.6076 0.284364C25.8499 0.485004 25.9165 0.756143 25.9484 0.946321C25.9804 1.1365 26.02 1.57 25.9884 1.90865C25.6281 5.77381 24.0691 15.1538 23.276 19.483C22.9404 21.3148 22.2801 21.929 21.6399 21.9891C20.2496 22.1197 19.1938 21.0509 17.8473 20.1497L12.5044 16.4853C10.1407 14.895 11.673 14.0208 13.0201 12.5923C13.3726 12.2183 19.4983 6.52939 19.6168 6.01332C19.6312 5.94878 19.6454 5.70825 19.5054 5.58114C19.3655 5.45403 19.1588 5.49803 19.0097 5.5321C18.7984 5.58109 15.4322 7.85276 8.91129 12.3472C7.95581 13.0171 7.09034 13.3436 6.31499 13.3265C5.46015 13.3076 3.81584 12.833 2.59346 12.4273C1.09413 11.9297 -0.0975042 11.6665 0.00630388 10.8214C0.0603147 10.3813 0.654003 9.93118 1.78732 9.47094L1.78737 9.47084Z" fill="#0C090F" />
                </svg>}
            {icon === 'youtube' &&
                <svg className={classes.icon} style={{ marginTop: '4px' }} width="50" height="54" viewBox="0 0 50 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={classes.icon2} d="M37.741 19.8839C37.741 19.8839 37.4871 18.1194 36.7051 17.3446C35.7148 16.3249 34.6078 16.3199 34.1 16.2599C30.4641 16 25.0051 16 25.0051 16H24.9949C24.9949 16 19.5359 16 15.9 16.2599C15.3922 16.3199 14.2852 16.3249 13.2949 17.3446C12.5129 18.1194 12.2641 19.8839 12.2641 19.8839C12.2641 19.8839 12 21.9583 12 24.0278V25.9672C12 28.0367 12.259 30.1111 12.259 30.1111C12.259 30.1111 12.5129 31.8756 13.2898 32.6504C14.2801 33.6701 15.5801 33.6351 16.159 33.7451C18.241 33.94 25 34 25 34C25 34 30.4641 33.99 34.1 33.7351C34.6078 33.6751 35.7148 33.6701 36.7051 32.6504C37.4871 31.8756 37.741 30.1111 37.741 30.1111C37.741 30.1111 38 28.0417 38 25.9672V24.0278C38 21.9583 37.741 19.8839 37.741 19.8839ZM22.3137 28.3216V21.1286L29.3367 24.7376L22.3137 28.3216Z" fill="#0C090F" />
                </svg>
            }
            {icon === 'reload' &&
                <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                    <path className={classes.icon3} d="M17.5 19.25L12.5 24.25L17.5 29.25" stroke="#0C090F" strokeWidth="2" />
                    <path className={classes.icon3} d="M7.42228 19.875C6.45919 18.2069 6.07344 16.2676 6.32486 14.3579C6.57627 12.4482 7.4508 10.6748 8.81282 9.31282C10.1748 7.9508 11.9482 7.07627 13.8579 6.82486C15.7676 6.57344 17.7069 6.95919 19.375 7.92228C21.0431 8.88536 22.3468 10.372 23.0839 12.1515C23.8211 13.9311 23.9504 15.9041 23.4519 17.7647C22.9533 19.6252 21.8548 21.2693 20.3267 22.4418C18.7985 23.6144 16.9262 24.25 15 24.25" stroke="#0C090F" strokeWidth="2" strokeLinecap="round" />
                </svg>
            }
            {icon === 'musicOn' &&
                <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
                    <path className={classes.icon2} d="M5.19797 17.9133C4.30672 16.4279 4.30672 14.5721 5.19797 13.0867C5.46995 12.6334 5.92052 12.3159 6.43887 12.2122L8.55516 11.789C8.68124 11.7638 8.79489 11.6961 8.8772 11.5974L12.1708 7.64498C13.3534 6.22592 13.9447 5.51638 14.4723 5.70742C15 5.89846 15 6.82207 15 8.66928L15 22.3307C15 24.1779 15 25.1015 14.4723 25.2926C13.9447 25.4836 13.3534 24.7741 12.1708 23.355L8.8772 19.4026C8.79489 19.3039 8.68124 19.2362 8.55516 19.211L6.43887 18.7878C5.92052 18.6841 5.46995 18.3666 5.19797 17.9133Z" fill="#0C090F" />
                    <path className={classes.icon3} d="M18.1694 11.0806C19.3355 12.2467 19.9935 13.8263 20 15.4754C20.0064 17.1244 19.3609 18.7092 18.2041 19.8845" stroke="#0C090F" strokeWidth="2" strokeLinecap="round" />
                    <path className={classes.icon3} d="M23.3211 8.42893C25.1868 10.2946 26.2395 12.8221 26.2499 15.4606C26.2603 18.0991 25.2275 20.6348 23.3766 22.5151" stroke="#0C090F" strokeWidth="2" strokeLinecap="round" />
                </svg>
            }
            {icon === 'musicOff' &&
                <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <path d="M5.19797 17.4133C4.30672 15.9279 4.30672 14.0721 5.19797 12.5867C5.46995 12.1334 5.92052 11.8159 6.43887 11.7122L8.55516 11.289C8.68124 11.2638 8.79489 11.1961 8.8772 11.0974L12.1708 7.14498C13.3534 5.72592 13.9447 5.01638 14.4723 5.20742C15 5.39846 15 6.32207 15 8.16928L15 21.8307C15 23.6779 15 24.6015 14.4723 24.7926C13.9447 24.9836 13.3534 24.2741 12.1708 22.855L8.8772 18.9026C8.79489 18.8039 8.68124 18.7362 8.55516 18.711L6.43887 18.2878C5.92052 18.1841 5.46995 17.8666 5.19797 17.4133Z" fill="#0C090F" />
                    <path d="M18.75 18.75L26.25 11.25" stroke="#0C090F" strokeWidth="2" strokeLinecap="round" />
                    <path d="M26.25 18.75L18.75 11.25" stroke="#0C090F" strokeWidth="2" strokeLinecap="round" />
                </svg>
            }
            {icon === 'account' &&
                <svg className={classes.icon} xmlns="http://www.w3.org/2000/svg" width={small ? 18 : 24} height="23" viewBox="0 0 24 23" fill="none">
                    <path className={classes.icon2} fillRule="evenodd" clipRule="evenodd" d="M12.0005 12.7665C15.516 12.7665 18.3658 9.90862 18.3658 6.38325C18.3658 2.85788 15.516 0 12.0005 0C8.48511 0 5.63529 2.85788 5.63529 6.38325C5.63529 9.90862 8.48511 12.7665 12.0005 12.7665ZM22.9886 19.614C23.2777 20.1498 22.9236 20.7746 22.3269 20.8951L19.9156 21.3818C14.6915 22.4365 9.30923 22.4365 4.08509 21.3818L1.67381 20.8951C1.07705 20.7746 0.723009 20.1498 1.01212 19.614C1.8029 18.1486 3.09972 16.8609 4.77945 15.8899C6.85101 14.6923 9.3892 14.0432 12.0003 14.0432C14.6115 14.0432 17.1497 14.6923 19.2212 15.8899C20.901 16.8609 22.1978 18.1486 22.9886 19.614Z" fill="#0C090F" />
                </svg>
            }
            {icon === "buyOption" &&
                <svg className={classes.icon} style={{ marginTop: '5px' }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path className={classes.icon4} d="M28.0607 13.0607C28.6464 12.4749 28.6464 11.5251 28.0607 10.9393L18.5147 1.3934C17.9289 0.807611 16.9792 0.807611 16.3934 1.3934C15.8076 1.97919 15.8076 2.92893 16.3934 3.51472L24.8787 12L16.3934 20.4853C15.8076 21.0711 15.8076 22.0208 16.3934 22.6066C16.9792 23.1924 17.9289 23.1924 18.5147 22.6066L28.0607 13.0607ZM0 13.5H27V10.5H0V13.5Z" fill="white" />
                </svg>
            }
        </div>
    );
};

export default ButtonTg;