import React, {useEffect, useState} from 'react';
import classes from "./block5.module.css";
import photo1 from "../../../../images/png/photo1.png";
import photo2 from "../../../../images/png/photo2.png";
import ButtonMain from "../../../common/buttonMain/buttonMain";
import {SERVER_URL} from "../../../../utils/api";

const Block5 = ({blocks, links}) => {
    const [commands, setCommands] = useState([
        {id:0, photo:photo1, name:'Стефан Буланов', prof:'Сооснователь&nbsp;школы "Криптомания"', info:'Учись, практикуйся, развивайся. Академия - это не просто обучающий курс. Здесь каждый участник заинтересован в развитии всей экосистемы. Учись, практикуйся, развивайся. Академия - это не просто обучающий курс. Здесь каждый участник заинтересован в развитии всей экосистемы.'},
        {id:1, photo:photo2, name:'Андрей Пряничников', prof:'Сооснователь&nbsp;школы "Криптомания"', info:'Учись, практикуйся, развивайся. Академия - это не просто обучающий курс. Здесь каждый участник заинтересован в развитии всей экосистемы. Учись, практикуйся, развивайся. Академия - это не просто обучающий курс.'},
        {id:2, photo:photo1, name:'Дмитрий Макаров', prof:'Педагог&nbsp;школы и эксперт&nbsp;по&nbsp;практике', info:'Учись, практикуйся, развивайся. Академия - это не просто обучающий курс. Здесь каждый участник заинтересован в развитии всей экосистемы. Учись, практикуйся, развивайся.'}
    ])
    useEffect(()=>{
        let temp = []
        for(let i=0;i<blocks.block6?.commands.length;i++){
            temp.push(
                {
                    id:i,
                    photo:SERVER_URL+blocks.block6?.commands[i].photo_url,
                    name:blocks.block6?.commands[i].name,
                    prof:blocks.block6?.commands[i].prof,
                    info:blocks.block6?.commands[i].text},
            )
        }
        setCommands(temp)
    },[blocks])
    return (
        <div className={classes._}>
            <div className={classes.block_}>
                <div className={classes.name}>наша команда</div>
                <div className={classes.text}>{blocks.block6?.text}</div>
                <div className={classes.commands}>
                    {commands.map(item =>
                        <div key={item.id} className={classes.item}>
                            <div className={classes.photoBlock}>
                                <img src={item.photo} alt={''} className={classes.photo}/>
                            </div>
                            <div className={classes.blockInfo}>
                                <div className={classes.nameCard} dangerouslySetInnerHTML={{__html: item.name}}></div>
                                <div className={classes.prof} dangerouslySetInnerHTML={{__html: item.prof}}></div>
                                <div className={classes.info} dangerouslySetInnerHTML={{__html: item.info}}></div>
                            </div>
                        </div>
                    )}
                </div>
                <div className={classes.block}>
                    <div className={classes.blockText1}>стань&nbsp;частью нашей&nbsp;команды</div>
                    <div id='price' className={classes.blockText2}>Заполни небольшую анкету с вопросами и в ближайшее время мы свяжемся с тобой.</div>
                    <ButtonMain text={"К вопросам"} str={true} color={0} click={()=>window.open(links.link)}/>
                </div>
            </div>
        </div>
    );
};

export default Block5;