import classes from "./LearningOptions.module.scss";
import ButtonTg from "../../buttonTG/ButtonTG";
import ButtonMain from "../../buttonMain/buttonMain";
import lock from '../../../../images/svg/profile/Union_lock.svg'
import { useContext } from "react";
import { ProfileContext } from "../../../pages/profile/contentBlock/MainContent";

function LearningOptions(props) {
    const context = useContext(ProfileContext)
    return (
        <div className={props.allowed ? classes.blockBuy : classes.blockLoked} onClick={() => context.profileDispatch({ type: "switch", payload: "advanced" })}>
            <h4>Блок {props.number}</h4>
            {props.allowed ?
                <div className={classes.lowerDeck}>
                    <span>
                        <p className={classes.allowedTitle}>Доступно :</p>
                        <p className={classes.allowedTime}>45 д-14 ч-25 м</p>
                    </span>
                    <ButtonTg icon={'buyOption'} />
                </div>
                :
                <>
                    <div className={classes.lowerDeck}>
                        <img src={lock} alt="locked" />

                    </div>
                    <div className={classes.buttonWrapper}>
                        <ButtonMain text={"купить"} color={0} />
                    </div>

                </>

            }
        </div>
    )
}
export default LearningOptions;